/* global googletag, gptadslots */
import { dfpManager } from '../../../base/js/dfp/dfp-manager';


googletag.cmd.push(() => {
  // Full-page banner
  const mapping1 = googletag.sizeMapping()
    .addSize([1024, 0], [[728, 90], [970, 90]])
    .addSize([768, 0], [728, 90])
    .addSize([0, 0], [[320, 50], [320, 100]])
    .build();

  // The above-the-header banner (LB1)
  const mappingHeader = googletag.sizeMapping()
    .addSize([1024, 0], [[728, 90], [970, 90]])
    .addSize([0, 0], [])
    .build();

  // For other 728x90 ad slots
  const mapping2 = googletag.sizeMapping()
    .addSize([1220, 0], [728, 90])
    .addSize([1024, 0], [])
    .addSize([768, 0], [728, 90])
    .addSize([0, 0], [[320, 50], [320, 100]])
    .build();

  // For 160x600
  const mapping3 = googletag.sizeMapping()
    .addSize([1024, 0], [[160, 600], [300, 600]])
    .addSize([0, 0], [[336, 280], [300, 250]])
    .build();

  // For 300x250 MPU4
  const mapping6 = googletag.sizeMapping()
    .addSize([1024, 0], [])
    .addSize([0, 0], [300, 250])
    .build();

  // Adslot 3 declaration
  gptadslots[3] = googletag.defineSlot(
    '/55877742/Police1',
    [[300, 250]],
    'div-gpt-ad-213395177806553216-3',
  ).setTargeting('Pos', ['MPU2']).addService(googletag.pubads());

  // Adslot 4 declaration
  gptadslots[4] = googletag.defineSlot(
    '/55877742/Police1',
    [[300, 250]],
    'div-gpt-ad-213395177806553216-4',
  ).setTargeting('Pos', ['MPU3']).addService(googletag.pubads());

  // Adslot 5 declaration
  gptadslots[5] = googletag.defineSlot(
    '/55877742/Police1',
    [[300, 90]],
    'div-gpt-ad-213395177806553216-5',
  ).setTargeting('Pos', ['30090-1']).addService(googletag.pubads());

  // Adslot 7 declaration
  gptadslots[7] = googletag.defineSlot(
    '/55877742/Police1',
    [[300, 90]],
    'div-gpt-ad-213395177806553216-7',
  ).setTargeting('Pos', ['30090-3']).addService(googletag.pubads());

  // Adslot 8 declaration
  gptadslots[8] = googletag.defineSlot(
    '/55877742/Police1',
    [[300, 90]],
    'div-gpt-ad-213395177806553216-8',
  ).setTargeting('Pos', ['30090-4']).addService(googletag.pubads());

  // Adslot 12 declaration
  gptadslots[12] = googletag.defineSlot(
    '/55877742/Police1',
    [[160, 600]],
    'div-gpt-ad-213395177806553216-12',
  ).setTargeting('Pos', ['WSKY1']).defineSizeMapping(mapping3)
    .addService(googletag.pubads());

  // Adslot 18 declaration
  gptadslots[18] = googletag.defineSlot(
    '/55877742/Police1',
    [[728, 90]],
    'div-gpt-ad-213395177806553216-18',
  ).setTargeting('Pos', ['LB1']).defineSizeMapping(mappingHeader)
    .addService(googletag.pubads());

  // A regular LB2 - a 728px ad in the content area
  gptadslots[20] = googletag.defineSlot(
    '/55877742/Police1',
    [[728, 90]],
    'div-gpt-ad-213395177806553216-20',
  ).setTargeting('Pos', ['LB2']).defineSizeMapping(mapping2)
    .addService(googletag.pubads());

  // Adslot 23 declaration
  gptadslots[23] = googletag.defineSlot(
    '/55877742/Police1_Skin',
    [[1, 1]],
    'div-gpt-ad-213395177806553216-23',
  ).addService(googletag.pubads());

  // Adslot 24 declaration
  gptadslots[24] = googletag.defineSlot(
    '/55877742/Police1',
    [[728, 90]],
    'div-gpt-ad-213395177806553216-24',
  ).setTargeting('Pos', ['LB3']).defineSizeMapping(mapping2)
    .addService(googletag.pubads());

  // Adslot 25 declaration
  gptadslots[25] = googletag.defineSlot(
    '/55877742/Police1',
    [[300, 250]],
    'div-gpt-ad-213395177806553216-25',
  ).setTargeting('Pos', ['MPU4']).defineSizeMapping(mapping6)
    .addService(googletag.pubads());

  // Adslot 26 declaration - Out of Page
  gptadslots[26] = googletag.defineOutOfPageSlot(
    '/55877742/Police1_Adhesion',
    'div-gpt-ad-213395177806553216-26',
  ).addService(googletag.pubads());

  // Adslot APB declaration
  gptadslots[27] = googletag.defineSlot(
    '/55877742/Police1_APB',
    [[355, 16]],
    'div-gpt-ad-4752924-1',
  ).addService(googletag.pubads());

  gptadslots[28] = googletag.defineSlot(
    '/55877742/Police1_Sponsored_Article',
    [[728, 90]],
    'div-gpt-ad-213395177806553216-28',
  ).setTargeting('Pos', ['SponsoredLB']).defineSizeMapping(mapping2)
    .addService(googletag.pubads());

  // A sticky LB2, it spans both the content and the sidebar
  gptadslots[29] = googletag.defineSlot(
    '/55877742/Police1',
    [[728, 90]],
    'div-gpt-ad-213395177806553216-29',
  ).setTargeting('Pos', ['LB2']).defineSizeMapping(mapping1)
    .addService(googletag.pubads());

  dfpManager.setTargets(googletag);
  dfpManager.enable();
});
